"use client";

import { apiRequest, refreshAccessToken } from "./api_request";

export async function handleRequest(
  endpoint: string,
  method: string = "GET",
  update: (session: any) => void,
  body: any = null,
  header_options: any = null,
) {
  let response = await apiRequest(endpoint, method, body, header_options);

  if (response.status == 401) {
    const refreshTokenRes = await refreshAccessToken()

    if (refreshTokenRes.status == 200) {
      const data = refreshTokenRes.data
      response = await apiRequest(endpoint, method, body, header_options, data.access_token)

      await update({
        user: {
          access_token: data.access_token,
          access_token_expires_at: data.access_token_expires_at,
        }
      });
    }
  }

  return response;
}

